import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { useEffect } from "react";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const FormContainer = styled.div`
  text-align: center;
  min-height: 70vh;
  width: 100%;

  .contactFormTitle {
    margin: 4rem 2rem;
    color: ${(props) => props.theme.colors.primary};

    @media (max-width: 1200px) {
      margin: 2rem 1rem;
      font-size: 2rem;
    }
  }

  .formLabel {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.primary};

    @media (max-width: 1200px) {
      font-size: 1.2rem;
    }
  }

  .formInput {
    width: 50%;
    padding: 2vh 2vw;
    border-radius: 10px;
    font-size: 2rem;

    &:hover {
      filter: drop-shadow(
        0 0 20px ${(props) => props.theme.colors.firstDetail}
      );
    }

    @media (max-width: 800px) {
      font-size: 2rem;
    }
    @media (max-width: 700px) {
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      font-size: 1.6rem;
      padding: 1rem 0.4rem;
    }

    @media (max-width: 500px) {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
    @media (max-width: 390px) {
      padding: 1rem 1.5rem;
      font-size: 1rem;
    }
  }
  .formInputMessage {
    font-family: "Montserrat", sans-serif;
    width: 50%;
    padding: 2vh 2vw;
    height: 15vh;
    border-radius: 10px;
    font-size: 2rem;

    &:hover {
      filter: drop-shadow(
        0 0 20px ${(props) => props.theme.colors.firstDetail}
      );
    }

    @media (max-width: 800px) {
      font-size: 2rem;
    }
    @media (max-width: 700px) {
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      font-size: 1.6rem;
      padding: 1rem 0.4rem;
    }

    @media (max-width: 500px) {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
    @media (max-width: 390px) {
      padding: 1rem 1.5rem;
      font-size: 1rem;
    }
  }

  .submitButton {
    text-align: center;
    cursor: pointer;
    font-size: 2rem;
    margin-top: 2rem;
    background-color: ${(props) => props.theme.colors.firstDetail};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    width: 100%;
    max-width: 20vw;
    padding: 2rem;

    &:hover {
      background-color: ${(props) => props.theme.colors.firstDetailDarker};
    }

    @media (max-width: 700px) {
      font-size: 1.5rem;
      padding: 1rem;
    }
    @media (max-width: 550px) {
      font-size: 1rem;
      padding: 1.5rem;
    }
    @media (max-width: 500px) {
      font-size: 0.8rem;
      padding: 1rem;
    }
  }
`;

function ContactForm(props) {
  const [hasMadeContect, setHasMadeContact] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const contactTitle = props.language.titles.contact;
  const contactLabels = props.language.contact;

  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const portugueseMaskGenerator = createDefaultMaskGenerator("99 99999-9999");
  const englishMaskGenerator = createDefaultMaskGenerator("+99 (999) 999 9999");

  const isPt = props.language.name === "pt";

  useEffect(() => {
    setPhoneNumber("");
  }, [isPt]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    const nameValue = nameRef.current?.value;
    const emailValue = emailRef.current?.value;
    const messageValue = messageRef.current?.value;

    if (
      nameValue === "" ||
      emailValue === "" ||
      messageValue === "" ||
      phoneNumber === ""
    ) {
      alert(
        isPt
          ? "Preencha os campos para enviar uma mensagem!"
          : "Fill out the fields to send a message!"
      );
    } else {
      const templateParams = {
        from_name: nameValue,
        from_email: emailValue,
        from_phone: phoneNumber,
        message: messageValue,
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((res) => {
          if (res.status === 200) {
            goToTop();
            setHasMadeContact(true);
          } else if (res.status === 404 || res.status === 500) {
            alert(
              isPt
                ? "Ocorreu um erro, tente novamente mais tarde"
                : "An error occurred, please try again later."
            );
            window.open("https://www.marcosviniciusferreira.com");
          }
        });
    }
  };

  return (
    <FormContainer theme={props.theme}>
      <h1 className="contactFormTitle">{contactTitle}</h1>
      {!hasMadeContect && (
        <form onSubmit={handleSendEmail}>
          <label>
            <p className="formLabel"> {contactLabels.name}</p>
            <input
              type="text"
              className="formInput"
              placeholder={contactLabels.name}
              ref={nameRef}
            />
          </label>
          <label>
            <p className="formLabel">{contactLabels.email}</p>
            <input
              type="text"
              className="formInput"
              placeholder={contactLabels.email}
              ref={emailRef}
            />
          </label>
          <label>
            <p className="formLabel">{contactLabels.phone}</p>
            <MaskedInput
              className="formInput"
              placeholder={contactLabels.phonePlaceholder}
              maskGenerator={
                isPt ? portugueseMaskGenerator : englishMaskGenerator
              }
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </label>
          <label>
            <p className="formLabel">{contactLabels.message}</p>
            <textarea
              className="formInputMessage"
              placeholder={contactLabels.messagePlaceholder}
              ref={messageRef}
            />
          </label>
          <div>
            <button type="submit" className="submitButton">
              {contactLabels.send}
            </button>
          </div>
        </form>
      )}
      {hasMadeContect && (
        <div>
          <h1>{contactLabels.successfulSendedMessage}</h1>
        </div>
      )}
    </FormContainer>
  );
}

export default ContactForm;
