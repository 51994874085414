import React, { createContext, useContext, useState } from 'react';
import darkBackground from '../assets/img/bg-dark.jpg';
import lightBackground from '../assets/img/bg-light.jpg';

const ThemeContext = createContext();

export const themes = [
    {
        name: 'dark',
        colors: {
            primary: '#fff',
            secondary: '#f73e75',
            firstDetail: '#BE2A36',
            firstDetailDarker: '#91212a',
            secondDetail: '#fab07c',
            backgroundColor: '#1d1d1d',
            buttonBackgroundColor: '#BE2A36',
        },
        background: darkBackground
    },
    {
        name: 'light',
        colors: {
            primary: '#1d1d1d',
            secondary: '#73f3a8',
            firstDetail: '#8840FC',
            firstDetailDarker: '#642dbd',
            secondDetail: '#0199FE',
            backgroundColor: '#fff',
            buttonBackgroundColor: '#1d1d1d',

        },
        background: lightBackground
    },
]


export default function Theme({ children }) {
    const [theme, setTheme] = useState(themes[1])

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export function useTheme() {
    const context = useContext(ThemeContext)
    const { theme, setTheme } = context
    return { theme, setTheme }
}
