import React from 'react'
import styled from 'styled-components';
import Image from '../../components/image/Image';
import { useTheme } from '../../context/Theme';
import { useLanguage } from '../../context/Language';
import { dataProjects } from '../../assets/data/DataProjects';

const ProjectsContainer = styled.div`
    width: 100%;
    min-height: 80vh;
    margin: 0;
`;

const ProjectsContent = styled.div`
    background-color: ${props => props.theme.colors.backgroundColor};
    border-radius: 20px;
    filter: drop-shadow(0 0 20px ${props => props.theme.colors.secondary});
    width: 70%;
    margin: 3em auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    h1 {
        color: ${props => props.theme.colors.primary};
    }
    @media (max-width: 1000px) {
    width: 75%;
    }
`;

const ProjectsBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin-top: 4rem;

    @media (max-width: 1500px) {
    margin-top: 2rem;

    }
`;

const ProjectItem = styled.div`
    background-color: ${props => props.theme.colors.backgroundColor};
    display: inline-block;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 10px;
    max-width: 90%;
    margin: 2rem;
    padding: 1rem;
    text-align: left;

    img {
        cursor: pointer;
        max-width: 500px;
        
        @media (max-width: 800px) {
            max-width: 100%;
    }
        @media (max-width: 500px) {
            max-width: 250px;
    }
        @media (max-width: 390px) {
            max-width: 100%;
    }

    }
    p {
        color: ${props => props.theme.colors.primary};
        max-width: 500px;
    }

    @media (max-width: 1000px) {
        margin: 1em;
    }
    h1 {
        font-size: 50px;
        color: ${props => props.theme.colors.primary};
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        
        @media (max-width: 800px) {
            font-size: 35px;
        }
        
        @media (max-width: 390px) {
            font-size: 20px;
        }
    }
    &:hover {
        filter: drop-shadow(0 0 20px ${props => props.theme.colors.secondary});

    }
    
`;

const ProjectButton = styled.button`
    display: block;
    padding: 15px;
    font-size: 20px;
    background-color: ${props => props.theme.colors.backgroundColor};
    flex: 1;
    cursor: pointer;

    a {
        text-decoration: none;
        color: ${props => props.theme.colors.primary};
    }


    &:hover {
        background-color: ${props => props.theme.colors.primary};
        border: 2px solid ${props => props.theme.colors.primary};

        a {
            color: ${props => props.theme.colors.backgroundColor};
        }
    }

    @media (max-width: 1000px) {
        padding: 10px;
        font-size: 15px;

    }
`;

export default function Projects() {

    const { theme } = useTheme()
    const { language } = useLanguage()

    function handleProject(reference) { window.open(`${reference}`, '_blank') }

    return (
        <ProjectsContainer>
            <ProjectsContent theme={theme}>
                <h1>{language.projects.header}</h1>
                {Object.values(dataProjects).length < 1 && (<h1>{language.projects.loading}</h1>)}
                {dataProjects.map((option, index) => {
                    return (
                        <ProjectsBox key={index}>
                            {option.name === language.name ?
                                option.projects.map((project) => {
                                    return (
                                        <ProjectItem key={project.id} theme={theme}>
                                            <Image image={project.image} alt={project.name} reference={project.referenceName} />
                                            <h1 onClick={() => handleProject(project.referenceName)}>{project.name}</h1>
                                            <p>{project.description}</p>
                                            <ProjectButton theme={theme}><a href={project.referenceName} rel="noopener noreferrer" target={"_blank"}>{language.projects.button}</a></ProjectButton>
                                        </ProjectItem>)
                                })
                                : null}
                        </ProjectsBox>)
                })}
            </ProjectsContent>
        </ProjectsContainer>
    )
}
