import React from 'react'
import { FaLinkedin, FaGithub, FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutgoingMail } from "react-icons/md";
import styled from 'styled-components';
import { useTheme } from '../../context/Theme';

const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SocialItem = styled.div`
    display: inline-block;
    margin: 0 0.5rem;

    a {
        text-decoration: none;
        color: white;
    }

    &:hover {
    filter: drop-shadow(0 0 20px ${props => props.theme === 'light' ? '#73f3a8' : '#f73e75'});
    cursor: pointer;


  }
`;

export default function Social() {

    const { theme } = useTheme()

    const target = "_blank"
    const github = "https://github.com/marcosnaofazisso"
    const linkedin = "https://www.linkedin.com/in/marcosvmferreira/"
    const gmail = "mailto:marcosferreiraf22@gmail.com"
    const youTube = "https://www.youtube.com/@marcosnaofazisso"
    const whatsApp = "https://api.whatsapp.com/send/?phone=5511989332002&text=Oi+Marcos%2C+acabei+de+visitar+o+seu+site+e+gostaria+de+conversar+com+voc%C3%AA+%3A%29"

    return (
        <SocialContainer >
            <SocialItem theme={theme.name}> <a href={github} target={target}><FaGithub /></a></SocialItem>
            <SocialItem theme={theme.name}> <a href={whatsApp} target={target}><IoLogoWhatsapp /></a></SocialItem>
            <SocialItem theme={theme.name}><a href={linkedin} target={target}><FaLinkedin /></a></SocialItem>
            <SocialItem theme={theme.name}><a href={youTube} target={target}><FaYoutube /></a></SocialItem>
            <SocialItem theme={theme.name}><a href={gmail} target={target}><MdOutgoingMail /></a></SocialItem>
        </SocialContainer>
    )
}
