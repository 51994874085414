import styled from "styled-components";

export const HomeContainer = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  max-width: 100vw;
  height: 90dvh;
  margin: 0;
`;

export const HomeContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem;

  @media (max-width: 750px) {
    flex-wrap: wrap;
  }
`;

export const HomeName = styled.div`
  display: inline-block;
  font-size: 3rem;

  h1 {
    /* color: ${(props) => props.theme}; */
    color: #fff;
    font-size: 5rem;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 800px) {
    margin: 1em;
    font-size: 18px;

    h1 {
      font-size: 40px;
    }
  }
  @media (max-width: 500px) {
    margin: 1em;
    font-size: 15px;

    h1 {
      font-size: 30px;
    }
  }
`;
export const HomeLogo = styled.div`
  display: inline-block;
  margin-top: 1rem;
  margin-right: 3rem;
  width: 250px;
  height: 250px;
  border-radius: 200px;
  background-image: url(${(props) => props.profile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0 0 30px white);

  @media (max-width: 800px) {
    margin-top: 2vh;
    width: 200px;
    height: 200px;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    width: 150px;
    height: 150px;
  }
`;

export const DescriptionContent = styled.div`
  /* color: ${(props) => props.theme}; */
  color: #fff;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

export const CvButton = styled.button`
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${(props) => props.theme.colors.firstDetail};
  border-radius: 5px;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 1.5rem;
  cursor: pointer;
  filter: drop-shadow(0 0 20px ${(props) => props.theme.colors.firstDetail});

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    a {
      filter: drop-shadow(
        0 0 10px ${(props) => props.theme.colors.secondDetail}
      );
    }
  }

  @media (max-width: 500px) {
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 12px;
    padding: 1rem;
    a {
      width: 50%;
    }
  }
`;
