import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components';
import { useTheme } from '../../context/Theme';
import { useLanguage } from '../../context/Language';

const MenuContainer = styled.nav`
    width: 100%;
    align-items: center;
`;

const MenuContent = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;

    li a {
        /* color:${props => props.theme};  */
        color: #fff;
    }
`;

const MenuItem = styled.li`
    font-family: 'Montserrat', sans-serif; 
    font-weight: bold;
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    
    a {
        text-decoration: none;
        font-size: 30px;
        margin: 2rem;
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);

        
        @media (max-width: 900px) {
            font-size: 28px;
        }
        @media (max-width: 800px) {
            font-size: 24px;
        }
        @media (max-width: 700px) {
            font-size: 20px;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
        @media (max-width: 500px) {
            font-size: 16px;
            margin: 0.5rem; 
        }
        @media (max-width: 400px) {
            font-size: 14px;
            margin: 0.5rem; 
        }

    }

    a:hover,
    a:active,
    a.active {
        filter: drop-shadow(0 0 3px ${props => props.theme.colors.backgroundColor});
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);

        padding-bottom: 0.25rem;
        /* border-bottom: 4px solid ${props => props.theme.colors.primary}; */
        border-bottom: 4px solid #fff;
        cursor: pointer;
    }

    
`;

function Menu() {

    const { theme } = useTheme()
    const { language } = useLanguage()

    return (
        <>
            <MenuContainer>
                <MenuContent theme={theme.colors.primary}>
                    <MenuItem theme={theme}><NavLink to="/">{language.titles.home}</NavLink></MenuItem>
                    <MenuItem theme={theme}><NavLink to="/about">{language.titles.about}</NavLink></MenuItem>
                    <MenuItem theme={theme}><NavLink to="/projects" >{language.titles.projects}</NavLink></MenuItem>
                    <MenuItem theme={theme}><NavLink to="/contact" >{language.titles.contact}</NavLink></MenuItem>
                </MenuContent>
            </MenuContainer >
        </>
    )
}

export default Menu