import React, { createContext, useContext, useState } from 'react'

const LanguageContext = createContext();

export const languages = [
    {
        name: 'pt',
        titles: {
            home: 'Home',
            about: 'Sobre',
            projects: 'Projetos',
            contact: 'Contato',
        },
        home: {
            role: "Desenvolvedor Full-Stack",
            button: 'Baixar CV',

        },
        about: {
            1: "Olá, me chamo Marcos Vinicius Ferreira, muito prazer!",
            2: "Formado em Análise e Desenvolvimento de Sistemas pela FIAP. Tenho 3 anos de experiência na área de desenvolvimento.",
            3: "Desenvolvi integrações e melhorias que geraram melhora de 50% na produtividade de clientes. Fiz parte de novos projetos de fintech e logística que melhoraram a operação dos clientes.",
            4: "Trabalhei por 4 anos em CX, atendendo dezenas de milhares de clientes. Meus diferenciais são comunicação e empolgação por aprender e ensinar.",
            5: "Sou apaixonado por aprender coisas diferentes e adoro criar soluções de forma criativa!",
            6: "Vamos bater um papo!",
        },
        projects: {
            header: 'Projetos',
            loading: 'Carregando...',
            button: 'Ver projeto',
        },
        contact: {
            header: "Enviar e-mail",
            name: "Nome",
            email: "Email",
            phone: "Telefone",
            phonePlaceholder: "(99) 99999-9999",
            message: "Mensagem",
            messagePlaceholder: "Digite sua mensagem aqui",
            send: "Enviar",
            successfulSendedMessage: "Mensagem enviada com sucesso! ✅"

        }
    },
    {
        name: 'en',
        titles: {
            home: 'Home',
            about: 'About',
            projects: 'Projects',
            contact: 'Contact',
        },
        home: {
            role: 'Full-Stack Developer',
            button: 'Download CV',
        },
        about: {
            1: "Hello, my name is Marcos Vinicius Ferreira, nice to meet you!",
            2: "Graduated in Systems Analysis and Development from FIAP. I have 3 years of experience in the development field.",
            3: "I've developed integrations and improvements that resulted in a 50% increase in customer productivity. I've been involved in new fintech and logistics projects that enhanced customer operations.",
            4: "I worked for 4 years in CX, assisting tens of thousands of customers. My strengths are communication and enthusiasm for learning and teaching.",
            5: "I'm passionate about learning different things and I love crafting solutions in a creative way!",
            6: "Let's chat!",
        },
        projects: {
            header: 'Projects',
            loading: 'Loading...',
            button: 'Check out project',
        },
        contact: {
            header: "Send e-mail",
            name: "Name",
            email: "Email",
            phone: "Phone",
            phonePlaceholder: "+99 (999) 999-9999",
            message: "Message",
            messagePlaceholder: "Type your message here",
            send: "Send",
            successfulSendedMessage: "Your message has been sended! ✅"
        }
    },
]


export default function Language({ children }) {
    const [language, setLanguage] = useState(languages[0])

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export function useLanguage() {
    const context = useContext(LanguageContext)
    const { language, setLanguage } = context
    return { language, setLanguage }
}
