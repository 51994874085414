import React from 'react'
import Menu from '../menu/Menu'
import styled from 'styled-components'

const HeaderContainer = styled.header`
    margin: 0 auto;
    padding: 0;
    text-align: center;
    
`;
const HeaderContent = styled.div`
    display: flex;
    justify-content: center;
    
`;

function Header() {

    return (
        <HeaderContainer>
            <HeaderContent>
                <Menu />
            </HeaderContent>
        </HeaderContainer>
    )
}
export default Header;