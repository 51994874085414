import React from 'react'
import { NavLink } from 'react-router-dom'
import profile from '../../assets/img/profile.jpg'
import { useLanguage } from '../../context/Language'
import { useTheme } from '../../context/Theme'
import { CvButton, DescriptionContent, HomeContainer, HomeContent, HomeLogo, HomeName } from './styles'


export default function Home() {

    const { theme } = useTheme()
    const { language } = useLanguage()

    return (
        <HomeContainer>
            <HomeContent>
                <HomeName theme={theme.colors.primary}>
                    <h1>Marcos Vinicius Ferreira</h1>
                    <DescriptionContent theme={theme.colors.primary}>
                        <p>{language.home.role}</p>
                    </DescriptionContent>
                    <CvButton theme={theme}>
                        <NavLink to="cv_marcos_vinicius_ferreira.pdf" target="_blank" download>{language.home.button}</NavLink>
                    </CvButton>
                </HomeName>
                <HomeLogo profile={profile} />
            </HomeContent>
        </HomeContainer>
    )
}

