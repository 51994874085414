import React from "react";
import Footer from "./pages/footer/Footer";
import Header from "./pages/header/Header";
import AppRoutes from "./AppRoutes";
import Wallpaper from "./components/wallpaper/Wallpaper";
import Theme from "./context/Theme";
import Language from "./context/Language";
import './App.css'

function App() {
  return (
    <>
      <Theme>
        <Language>
          <Wallpaper>
            <Header />
            <AppRoutes />
            <Footer />
          </Wallpaper>
        </Language>
      </Theme>
    </>

  );
}

export default App;
