import pokedex from '../img/pokedex.png'
import marvel from '../img/marvel.png'
import oslo from '../img/oslo.png'
import natal from '../img/natal.png'
import aluratube from '../img/aluratube.png'
import vans from '../img/vans.png'
import casaflow from '../img/casaflow.png'
import cantandoSerto from '../img/cantando-serto.png'

export const dataProjectImages = {
    aluratube,
    pokedex,
    marvel,
    oslo,
    natal,
    vans,
    casaflow,
    cantandoSerto
}

export const dataProjects = [
    {
        name: 'pt',
        projects: [
            {
                id: 8,
                name: "CasaFlow",
                referenceName: "https://play.google.com/store/apps/details?id=com.marcosviniciusferreira.casaflow",
                image: casaflow,
                description: "Aplicativo de gerenciamento de finanças pessoais. Desenvolvimento nativo com Java e Android Studio. App publicado na Play Store."
            },
            {
                id: 7,
                name: "Cantando Serto",
                referenceName: "https://cantando-serto.com.br/",
                image: cantandoSerto,
                description: "Site de entretenimento desenvolvido em NextJS, com TypeScript, Toasify, EmailJS e integração com Google Analytics."
            },
            {
                id: 6,
                name: "Vans",
                referenceName: "https://marcosviniciusferreira-vans.vercel.app/",
                image: vans,
                description: "Site Vans desenvolvido com ReactJS e biblioteca de modelagem 3D ThreeJS."
            },
            {
                id: 5,
                name: "AluraTube",
                referenceName: "https://marcosviniciusferreira-aluratube.vercel.app/",
                image: aluratube,
                description: "Clone de YouTube desenvolvido utilizando React com NextJS e persistência em banco de dados Supabase."
            },
            {
                id: 4,
                name: "Pokédex",
                referenceName: "https://marcosviniciusferreira-pokedex.vercel.app/",
                image: pokedex,
                description: "Projeto de Pokedex desenvolvida utilizando React API RESTful com Java. Posteriormente adapatado para projeto Flask."
            },
            {
                id: 3,
                name: "Personagens Marvel",
                referenceName: "https://marcosviniciusferreira-marvel.vercel.app/",
                image: marvel,
                description: "Projeto de escolha de personagens do Universo Marvel. Foi utilizado ReactJS, uso de contexto (useContext), paginação e API RESTful com Flask."
            },
            {
                id: 2,
                name: "Feliz Natal",
                referenceName: "https://marcosviniciusferreira-natal.vercel.app/",
                image: natal,
                description: "Site básico de Feliz Natal a fins de estudo. Desenvolvido com HTML5 e CSS3."
            },
            {
                id: 1,
                name: "Café Oslo",
                referenceName: "https://marcosviniciusferreira-oslo.vercel.app/",
                image: oslo,
                description: "Site básico do Café Oslo a fins de estudo. Desenvolvido com HTML5 e CSS3."
            },]
    },
    {
        name: 'en',
        projects: [
            {
                id: 8,
                name: "CasaFlow",
                referenceName: "https://play.google.com/store/apps/details?id=com.marcosviniciusferreira.casaflow",
                image: casaflow,
                description: "Personal finance management application. Developed natively using Java and Android Studio. App published on the Play Store."
            },
            {
                id: 7,
                name: "Cantando Serto",
                referenceName: "https://cantando-serto.com.br/",
                image: cantandoSerto,
                description: "Entertainment website developed using NextJS, with TypeScript, Toasify, EmailJS, and integration with Google Analytics."
            },
            {
                id: 6,
                name: "Vans",
                referenceName: "https://marcosviniciusferreira-vans.vercel.app/",
                image: vans,
                description: "Vans website developed using ReactJS and 3D modeling library ThreeJS."
            },
            {
                id: 5,
                name: "AluraTube",
                referenceName: "https://marcosviniciusferreira-aluratube.vercel.app/",
                image: aluratube,
                description: "YouTube clone developed using React with NextJS. Project data was stored in Supabase cloud database."
            },
            {
                id: 4,
                name: "Pokédex",
                referenceName: "https://marcosviniciusferreira-pokedex.vercel.app/",
                image: pokedex,
                description: "Project of a Pokedex developed using React RESTful API with Java. Posteriorly adapted to Flask API."
            },
            {
                id: 3,
                name: "Marvel Characters",
                referenceName: "https://marcosviniciusferreira-marvel.vercel.app/",
                image: marvel,
                description: "Project to choose up to 6 characters of Marvel Universe. It was made by using ReactJS, use of context, pagination and RESTful API with Flask."
            },
            {
                id: 2,
                name: "Merry Christmas ",
                referenceName: "https://marcosviniciusferreira-natal.vercel.app/",
                image: natal,
                description: "Basic Merry Christmas ficcional website for studing purposes. Developed with just HTML5 and CSS3."
            },
            {
                id: 1,
                name: "Café Oslo",
                referenceName: "https://marcosviniciusferreira-oslo.vercel.app/",
                image: oslo,
                description: "Basic Café Oslo ficcional website for studing purposes. Developed with just HTML5 and CSS3."
            }]
    }]