import styled from "styled-components";

export const WallpaperContainer = styled.div`
  border: 0;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  background-image: url(${(props) => props.theme.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  max-width: 100%;
  height: auto;
  overflow: hidden;

  /* &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #a9ffcd;
  }

  &::-webkit-scrollbar-button {
    background-color: #8840fc;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #73f3a8, #00fce3);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f73e75;
  } */
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem;
`;

export const Select = styled.select`
  width: 10vw;
  height: 40px;
  font-size: 20px;
  margin-right: 2vw;

  @media (max-width: 1300px) {
    font-size: 18px;
    width: 15vw;
    height: 30px;
    margin-right: 3vw;
  }
  @media (max-width: 650px) {
    font-size: 15px;
    width: 25vw;
    height: 35px;
    margin-right: 5vw;
  }
`;
