import React, { useState } from 'react'
import { useLanguage } from '../../context/Language'

export default function Image(props) {

    const [isLoaded, setLoaded] = useState(false)
    const { language } = useLanguage()

    function handleReference(reference) {
        window.open(reference, '_blank')

    }

    return (
        <>
            {isLoaded ? null : <p className='loading'>{language.projects.loading}</p>}
            {props.image ?
                <img src={props.image} alt={props.alt} onLoad={() => setLoaded(!isLoaded)} onClick={() => handleReference(props.reference)} /> :
                <img src={props.picture} alt={props.alt} onLoad={() => setLoaded(!isLoaded)} />}
        </>
    );

}
