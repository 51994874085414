import React from "react";
import styled from "styled-components";
import ContactForm from "../../components/contactForm/ContactForm";
import { useLanguage } from "../../context/Language";
import { useTheme } from "../../context/Theme";

const ContactContainer = styled.div`
  width: 100%;
  min-height: 80vh;
  margin: 0;
  text-align: center;
`;
const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 70%;
  padding: 4vh 5vw;
  border-radius: 30px;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  filter: drop-shadow(0 0 20px ${(props) => props.theme.colors.terciary});
  margin: 2em auto;
  align-items: center;
  justify-content: center;

  @media (max-width: 1500px) {
    width: 80%;
  }
`;

function Contact() {
  const { theme } = useTheme();
  const { language } = useLanguage();

  return (
    <ContactContainer>
      <ContactContent theme={theme}>
        <ContactForm language={language} theme={theme} />
      </ContactContent>
    </ContactContainer>
  );
}

export default Contact;
