import React from 'react'
import styled from 'styled-components';
import picture from '../../assets/img/about-picture.JPG'
import Image from '../../components/image/Image';
import { useTheme } from '../../context/Theme';
import { useLanguage } from '../../context/Language';
import { NavLink } from 'react-router-dom';


const AboutContainer = styled.div`
    font-family: 'Montserrat', sans-serif; 
    font-weight: bold;
    width: 100%;
    min-height: 80vh;
    margin: 0;
    text-align: center;


`;
const AboutContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    width: 50%;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.backgroundColor};
    filter: drop-shadow(0 0 20px ${props => props.theme.colors.secondary});
    margin: 2em auto;
    align-items: center;
    justify-content: center;


    @media (max-width: 1500px) {
        width: 80%;

    }

`;

const AboutText = styled.div`
    text-align: left;
    max-width: 100%;
    color: ${props => props.theme.colors.primary};
    padding: 2em;
    font-size: 25px;

    @media (max-width: 800px) {
    font-size: 18px;
    padding: 2em;

    }
    @media (max-width: 500px) {
    font-size: 14px;
    padding: 2em;

    }

`;

const AboutImage = styled.div`
    text-align: center;
    width: 80%;

    img {
        margin-top: 3em;
        width: 80%;
        border-radius: 30px;

    }
    .loading {
        color: ${props => props.color};
    }
    
`;

function About() {

    const { theme } = useTheme()
    const { language } = useLanguage()

    return (
        <AboutContainer>
            <AboutContent theme={theme}>
                <AboutText theme={theme}>
                    <p>{language.about[1]}</p>
                    <p>{language.about[2]}</p>
                    <p>{language.about[3]}</p>
                    <p>{language.about[4]}</p>
                    <p>{language.about[5]}</p>
                    <NavLink to="/contact">{language.about[6]}</NavLink>
                </AboutText>
                <AboutImage theme={theme}>
                    <Image picture={picture} alt={"Foto de perfil do Marcos"} reference={'profile'} />
                </AboutImage>
            </AboutContent>
        </AboutContainer>
    )
}

export default About