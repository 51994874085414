import React, { useState } from "react";
import Switch from "react-switch";
import { languages, useLanguage } from "../../context/Language";
import { themes, useTheme } from "../../context/Theme";
import { ButtonContainer, Select, WallpaperContainer } from "./styles";

export default function Wallpaper({ children }) {
  const { theme, setTheme } = useTheme();
  const { setLanguage } = useLanguage();

  const [toggle, setToggle] = useState(true);

  function changeTheme() {
    if (theme.name === "light") {
      setTheme(themes[0]);
      setToggle(!toggle);
    } else {
      setTheme(themes[1]);
      setToggle(!toggle);
    }
  }

  function changeLanguage(choice) {
    if (languages.name !== choice) {
      setLanguage(languages[choice]);
    }
  }

  return (
    <WallpaperContainer theme={theme} as={"div"}>
      <ButtonContainer>
        <Switch
          onChange={() => changeTheme()}
          checked={toggle}
          offColor="#f73e75"
          onColor="#73f3a8"
          height={40}
          width={80}
          checkedIcon={null}
          uncheckedIcon={null}
        />
        <Select onChange={(e) => changeLanguage(e.target.value)}>
          <option value="0" defaultValue>
            Português
          </option>
          <option value="1">English</option>
        </Select>
      </ButtonContainer>
      {children}
    </WallpaperContainer>
  );
}
