import React from "react";
import styled from "styled-components";
import Social from "../social/Social";

const FooterContainer = styled.footer`
  bottom: 0;
  max-width: 100dvw;
  text-align: center;
`;

const FooterContent = styled.div`
  margin: 0 auto;
  background-color: #000000;
  padding: 2rem;

  @media (max-width: 500px) {
    padding: 1rem;
  }
`;

const MainFooter = styled.div`
  font-size: 4em;

  @media (max-width: 500px) {
    font-size: 3em;
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <MainFooter>
          <Social />
        </MainFooter>
      </FooterContent>
    </FooterContainer>
  );
}
